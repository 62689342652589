var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "calendar-content"
  }, [_c('Calendar', {
    ref: "calendar",
    attrs: {
      "task-view-open": _vm.taskViewWidgetOpen,
      "tasks": _vm.calendarTasks,
      "shared-resource-tasks": _vm.sharedResourceTasks
    },
    on: {
      "taskUpdated": _vm.emitTasksReload,
      "openTaskAddWidget": function (value) {
        return _vm.openTaskAddWidget(undefined, null, value);
      },
      "openTaskViewWidget": _vm.openTaskViewWidget
    }
  }), _c('CalendarSidePanel', {
    attrs: {
      "error-retrieving-tasks": _vm.errorRetrievingTasks,
      "tasks": _vm.sidepanelTasks
    },
    on: {
      "addParticipants": function (value) {
        return _vm.openTaskAddWidget(value, 'participants');
      },
      "openTaskViewWidget": _vm.openTaskViewWidget,
      "addTask": function (value) {
        return _vm.openTaskAddWidget(undefined, null, value);
      },
      "getTasks": _vm.emitTasksReload
    }
  }), _vm.taskAddWidgetOpen ? _c('TaskAddWidget', {
    attrs: {
      "resource": _vm.selectedResource,
      "focus-field": _vm.focusOnTaskAddField,
      "task-to-edit": _vm.taskToEdit,
      "new-task-time": _vm.taskAddWidgetTaskTime,
      "newCreatedContact": _vm.newCreatedContact,
      "contactModalOpen": _vm.contactModalOpen
    },
    on: {
      "addButtonClicked": _vm.addButtonClicked,
      "clearFocusField": function ($event) {
        _vm.focusOnTaskAddField = null;
      },
      "getTasks": _vm.emitTasksReload,
      "closeWidget": _vm.closeTaskAddWidget
    }
  }) : _vm._e(), _vm.taskViewWidgetOpen && !_vm.isDeleteModalOpen ? _c('TaskViewWidget', {
    attrs: {
      "task": _vm.taskViewWidgetValue
    },
    on: {
      "openProjectSidepanel": _vm.openProjectSidepanel,
      "openContactSidepanel": _vm.openContactSidepanel,
      "addParticipants": function (value) {
        return _vm.openTaskAddWidget(value, 'participants');
      },
      "openDeleteModal": _vm.openDeleteModal,
      "taskUpdated": _vm.viewTaskUpdated,
      "openTask": _vm.openParentTask,
      "editTask": _vm.openTaskAddWidget,
      "closeWidget": _vm.closeTaskViewWidget
    }
  }) : _vm._e(), _vm.isDeleteModalOpen ? _c('DeleteModal', {
    on: {
      "canceled": function ($event) {
        _vm.isDeleteModalOpen = false;
      },
      "approved": _vm.deleteTask
    }
  }) : _vm._e()], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }