var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "calendar-header"
  }, [_c('div', {
    staticClass: "calendar-header-title",
    staticStyle: {
      "display": "flex",
      "flex-wrap": "nowrap",
      "justify-content": "space-between"
    }
  }, [_c('h3', [_vm._v(_vm._s(_vm.$t("Calendar")))]), _c('BaseButton', {
    attrs: {
      "display-key": 'name',
      "display-value": 'value',
      "items": _vm.exportItems,
      "icons-style": {
        width: '16px',
        height: '16px'
      },
      "label": _vm.$t('Export'),
      "icon-left": 'share2.svg'
    },
    on: {
      "itemSelected": _vm.exportButtonSelection
    }
  })], 1)]);

}
var staticRenderFns = []

export { render, staticRenderFns }