<template>
  <div>
    <Header
      @selectedResource="loadResourcesCalendar"
      :taskTypes="taskTypes"
      :update-element="headerRefresh"
    />
    <Content
      @openProjectSidepanel="openProjectSidepanel"
      @openContactSidepanel="openContactSidepanel"
      @getTasks="getTasks"
      @viewTaskUpdated="viewTaskUpdated"
      :error-retrieving-tasks="errorRetrievingTasks"
      :selected-resource="selectedResource"
      :selected-contact-id="selectedContactId"
      :selected-project-id="selectedProjectId"
      :shared-resource-tasks="sharedResourceTasks"
      :calendar-tasks="calendarTasks"
      :sidepanel-tasks="sidepanelTasks"
    />
    <ClientSidepanel
      v-if="selectedContactId"
      @panelClosed="selectedContactId = null"
    />
  </div>
</template>

<script>
import Header from "@/components/CalendarView/Header";
import Content from "@/components/CalendarView/Content";
import taskTypes from "@/components/CalendarView/taskTypes";
import ClientSidepanel from "@/components/Kliendiregister/ClientSidepanel.vue";
import axios from "axios";
import { mapGetters, mapActions } from "vuex";
import moment from "moment";

export default {
  name: "CalendarView",
  components: {
    Header,
    Content,
    ClientSidepanel,
  },
  data() {
    return {
      selectedProjectId: null,
      selectedContactId: null,
      headerRefresh: 0,
      sidepanelTasks: null,
      calendarTasks: null,
      sharedResourceTasks: null,
      selectedResource: null,
      taskTypes: [],
      errorRetrievingTasks: false,
    };
  },
  created() {
    window.addEventListener("beforeunload", this.updateBackButton);
    this.taskTypes = taskTypes;
    this.getTasks();
    this.getSharedResources();
  },
  beforeDestroy() {
    this.updateBackButton();
  },
  computed: {
    ...mapGetters(["user", "showBackButton", "calendarShowResources"]),
  },

  watch: {
    calendarShowResources() {
      this.getCalendarTasks();
    },
  },
  methods: {
    ...mapActions(["setShowBackButton", "updateBackButton"]),
    async openProjectSidepanel(id) {
      this.selectedProjectId = id;
      await this.$router.push({
        name: "CalendarDealDetail",
        params: { id: id },
      });
    },
    async openContactSidepanel(id) {
      this.selectedContactId = id;
      await this.$router.push({
        name: "CalendarCustomerDetail",
        params: { id: id },
      });
    },
    async getTasks(updateViewName = null) {
      switch (updateViewName) {
        case "sidepanel":
          await this.getBrokerTasks();
          break;
        case "calendar":
          await this.getCalendarTasks();
          break;
        default:
          await this.getBrokerTasks();
          await this.getCalendarTasks();
      }
        await this.loadResourcesCalendar();
    },
    async getBrokerTasks() {
      this.errorRetrievingTasks = false;

      let endDate = moment().startOf('month').add(3, 'months').format('YYYY-MM-DD');

      const url = `/api/tasks/active/assignee/${this.user._id}?end=${endDate}`;

      await axios
        .get(url)
        .then((response) => {
          if (
            response &&
            response.status &&
            response.status === 200 &&
            response.data
          ) {
            this.sidepanelTasks = response.data;
          } else {
            this.errorRetrievingTasks = true;
          }
        })
        .catch((e) => {
          console.log("error here", e);
          this.errorRetrievingTasks = true;
        });
      console.log(this.sidepanelTasks)
    },
    async getCalendarTasks() {
      let startDate = moment().startOf('month').subtract(7, 'days').format('YYYY-MM-DD');
      let endDate = moment().startOf('month').add(3, 'months').format('YYYY-MM-DD');

      let url;
      if (!this.calendarShowResources) {
        url = `/api/tasks/user/events/${this.user._id}`
      } else {
        url = `/api/tasks/user/events/${this.user._id}/1`

      }
      url += `?start=${startDate}&end=${endDate}`;
      // calendarShowResources
      await axios
        .get(url)
        .then((response) => {
          if (response && response.status === 200) {
            this.calendarTasks = response.data;
          } else {
            console.log("Couldn't get tasks");
          }
        });

      if (!this.user.microsoft) return;
      const access = await this.getMsalAccessToken();
      if (access) {
        const token = access.accessToken;
        const userTimezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
        await axios.post(`/api/tasks/microsoft/all`, { token, userTimezone });
        await axios
          .get(`/api/tasks/user/events/${this.user._id}`)
          .then((response) => {
            if (response && response.status === 200) {
              this.calendarTasks = response.data;
            } else {
              console.log("Couldn't get tasks");
            }
          });
      }
    },
    async getSharedResources() {
      await axios.get("/api/admin/get-shared-resources").then((response) => {
        if (
          response &&
          response.status &&
          response.status === 200 &&
          response.data
        ) {
          const taskType = this.taskTypes.find(
            (taskType) => taskType.value === "resource"
          );
          taskType.dropdownItems = response.data;
          this.sharedResourceTasks = response.data;
        }
      });
    },
    viewTaskUpdated(task) {
      if (!this.sidepanelTasks) return;
      this.sidepanelTasks.forEach((oldTask) => {
        if (oldTask._id === task._id) {
          oldTask.isCompleted = task.isCompleted;
        }
      });
    },
  },
};
</script>

<style scoped></style>
