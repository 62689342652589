<template>
  <div class="calendar-header">
    <div class="calendar-header-title" style="display: flex; flex-wrap: nowrap; justify-content: space-between">
      <h3>{{ $t("Calendar") }}</h3>
      <BaseButton @itemSelected="exportButtonSelection" :display-key="'name'" :display-value="'value'" :items="exportItems"
                  :icons-style="{width: '16px', height: '16px'}" :label="$t('Export')" :icon-left="'share2.svg'"/>
    </div>
  </div>
</template>

<script>
import {mapGetters} from "vuex";
import BaseButton from "@/components/common/BaseButton";

export default {
  name: "Header",
  components: {BaseButton},
  props: ["taskTypes", "updateElement"],
  data() {
    return {
      exportItems: [
        {
          name: this.$t('CopyiCalUrl'),
          value: "COPY_ICAL_URL"
        }
      ],
      elementUpdateKey: 0,
      selectedResource: null,
    }
  },
  computed: {
    ...mapGetters(["user"]),
  },
  watch: {},
  methods: {
    async exportButtonSelection(buttonSelection) {
      const iCalFileName = this.user.access?.features?.modules?.calendar?.features?.ical?.location;
      if (!iCalFileName) {
        this.toastError(this.$t('SomethingWentWrong'))
        return;
      }
      switch (buttonSelection.value) {
        case "COPY_ICAL_URL":
          await navigator.clipboard.writeText(iCalFileName)
          this.toastSuccess('iCal ' + this.$t('LinkCopied'))
          break;
      }
    }
  }
}
</script>

<style scoped>
.calendar-header {
  margin-left: 64px;
  background: #fff;
}

.calendar-header-title {
  height: 72px;
  padding: 16px 48px 24px 48px;
  border-bottom: 1px solid #F4F5F7;
}

.calendar-header-filters {
  height: 64px;
  padding: 22px 48px;
  display: flex;
  gap: 32px;
}

.calendar-header-filter {
  gap: 12px;
  display: flex;
  position: relative;
}

.calendar-header-filter-color {
  border-radius: 4px;
  height: 20px;
  width: 20px;
}

.calendar-header-filter-content {
  display: flex;
  gap: 4px;
}

.calendar-header-filter-dropdown {
  position: absolute;
  cursor: pointer;
  border: 1px solid #E6E8EC;
  border-radius: 8px;
  outline: none;
  background: white;
  top: 20px;
  width: 200px;
  z-index: 2;
}

.calendar-header-filter-dropdown-item {
  padding: 12px 16px;
  display: flex;
  flex-wrap: nowrap;
  gap: 10px;
}

.calendar-header-filter-dropdown-item:hover {
  background: #F4F5F7;
}

.clickable-filter {
  cursor: pointer;
}
</style>